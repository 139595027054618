<template>
    <div id="app" >
        <div style="width: 100%;height:100%;background: #E3E4E7;overflow: hidden">
            <div style="display: flex">
                <!--  编辑器-->
                <div style="width:84%;overflow-x: hidden;overflow-y: hidden;">
                    <div class="avatar-uploader" style="float:left;overflow: hidden">
                        <div style="width: 1535px;" >
                            <div style="margin-left:9px;background: #FFFFFF;overflow:auto;height: 880px;">
                                <div style="width:1500px;float: left;">
                                    <div style="height: 40px;background: #7274A7;margin-left:17px;margin-top: 10px;margin-bottom: 10px;">
                                        <input style="width:100%;height: 40px;border: none;font-size: 26px;font-weight: bold;" placeholder=" 请输入文章标题（不超过100个字）" v-model="article.title"  value="" />
                                    </div>
                                    <!--编辑器-->
                                    <div style="margin-left: 15px;">
                                        <!-- :tinymce_height="'770'"  :innerDrawer = "innerDrawer = true" :value="content"  ref="ch"  -->
                                        <div style="width:1480px;height:790px;border: 1px solid #999999;">
                                            <Editor :tinymce_height="'770'"  :innerDrawer = "innerDrawer = true" :value="article.content"  ref="ch"></Editor>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- 右侧-->
                <div style="height:100%;margin-left:6px">
                    <div class="function" style="float:right;">
                        <div style="margin-top: -35px;width:375px;height: 875px" >
                            <div style="float: left;width: 375px;height:100%;margin-top: 36px;background-color: #FFFFFF;">
                                <!--封面设置-->
                                <div style="overflow: hidden;">
                                    <div style="float: left;margin-left: 21px;width: 100%" > <div style="float: left;vertical-align: middle;font-size: 14px;color: rgb(138, 138, 138);line-height: 35px;box-sizing: border-box;margin-left: 10px">设置封面</div>
                                        <el-upload class="upload-demo"   style="margin-left: 12px;"  drag action="" :http-request="upload_img2" :show-file-list="false" v-if="article.cover == '' ">
                                            <i class="el-icon-plus" style="width: 24px;height: 41px;font-size: 35px;color: #2970FF;margin-top:40px;text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);font-weight: 400;"></i>
                                            <div class="el-upload__text">将图片拖到此处，或<em>点击上传</em></div>
                                            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过20MB</div>
                                        </el-upload>
                                    </div>
                                    <div class="avatar" :style="{backgroundImage:'url('+article.cover+'-200x200)'} " v-if="article.cover != ''">
                                        <div style="    background:#ff7575;color:#FFF;text-align:center;width:20px;line-height:20px;float:right; cursor:pointer;user-select: none;" @click="removeImg()">
                                            <i class="el-icon-close"></i>
                                        </div>
                                    </div>
                                </div>
                                <!--生成文章封面-->
                                <div style="height: auto; margin-top: 15px;margin-left: 30px">
                                    <el-button style="float:left;background: #2970FF;" type="info" size="mini" @click="create_thumb_pic()" >生成文章封面</el-button>
                                    <p style="font-size: 12px;color: #2970FF;line-height: 28px;margin-left: 120px;"><span style="color: #2970FF;">注：</span>如遇微信图片须耐心等待几秒钟</p>
                                    <div style="overflow: hidden;width: 350px" >
                                        <div v-for="(item,index) in thumbs" :key="index">
                                            <div v-if="item.is_selected == 1">
                                                <div v-for="(item2,index2) in item.imgs" :key="index2" @click="change_border(item2.img,index2)" class="cover_border" :class="{'active':item2.checked==1}">
                                                    <img style="width: 100%;height:100%;overflow: hidden;" :src="item2.img+'-300x150'">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--  文章来源     -->
                                <div style="background-color: #FFFFFF">
                                    <div style="width:100%;margin-top: 8px;display: flex;">
                                        <div style="width: 60px;height: 17px;font-size: 15px;color: #545454;margin-left: 30px">文章来源</div>
                                        <div style="margin: 0 15px;font-size: 14px;">
                                            <template>
                                                <el-radio v-model="form" label="1">原创</el-radio>
                                                <el-radio v-model="form" label="2">转载推荐</el-radio>
                                            </template>
                                        </div>

                                    </div>
                                    <!--  输入框       -->
                                    <div style="height: 39.5px;width: 80%;border-radius: 5px 5px 5px 5px;border: 1px solid #999999;margin-top: 10px;margin-left: 30px">
                                        <el-input  v-model="article.reprintedAddress" placeholder="请填写原文链接"></el-input>
                                    </div>

                                    <div style="width: 100%;margin: 7px 30px;">
                                        <el-checkbox v-model="isreprinted">*原文需允许转载或本次转载已获得原文作者授权</el-checkbox>
                                    </div>

                                    <div class="block">
                                        <div style="float: left;vertical-align: middle;font-size: 14px;color: rgb(138, 138, 138);line-height: 35px;padding: 3px 12px 0 0;box-sizing: border-box;margin-left: 15px">上传附件:</div>
                                        <div class="input_box_btn op sc hand" @click="upload_file_open = true" style="position: relative; margin-right: 8px">
                                            <div class="input_box_btn_icon" style="margin-top: 4px"><img src="./imgs/event.png"/></div>
                                            <el-badge style="position:absolute; z-index:10; left:45px;bottom:5px;" v-if="article.enclosure.length > 0" :value="article.enclosure.length"></el-badge >
                                        </div>
                                    </div>

                                    <!--传文件-->
                                    <el-dialog :show-close=false :visible.sync="upload_file_open" width="398px">
                                        <el-tabs v-model="file_tag_name" type="border-card">
                                            <el-tab-pane label="电脑上传" name="pc" >
                                                <div>
                                                    <el-upload accept=".pdf, .doc, .docx, .xls, .xlsx"  class="upload-demo"  action="" drag multiple :http-request="upload_file" :show-file-list="false">
                                                        <i class="el-icon-upload"></i>
                                                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                                    </el-upload>
                                                </div>
                                            </el-tab-pane>
                                            <el-tab-pane label="手机上传" name="mobile">
                                                <div style="padding:10px;color:#a6a6a6;line-height:20px;">
                                                    <p>使用微信“扫一扫”打开小程序</p><P>在 百工宝小程序 中向“文件助手”发送文件</P>
                                                </div>
                                                <div style="text-align:center;">
                                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/img/xcx_qrcode.jpg-80*80" style="width:150px;height:150px;">
                                                </div>
                                            </el-tab-pane>
                                        </el-tabs>
                                        <div style="overflow: hidden; height:20px;line-height:20px;">
                                            <div style="float:left;color:#acacac;">
                                                文件数:{{article.enclosure.length}}<!-- /9 -->
                                            </div>
                                            <div style="background:#FFF; text-align:center;width:20px;height:20px;line-height:20px;border-radius:10px;float:right; cursor:pointer;user-select: none;" @click="upload_file_open = false">
                                                <i class="el-icon-close" style="color:#ff7575"></i>
                                            </div>
                                        </div>
                                        <div style="overflow:hidden;">
                                            <div class="video" v-for="(item,index) in article.enclosure" :key="index" style="width:80px;height:100px;border-radius:3px; float:left; margin:5px; background-size:100%;position:relative;" :title="item.file_name">
                                                <div style="width:100%;height:calc(100% - 20px);background-color: rgba(0,0,0,.3);">
                                                    <img :src="ossUrl+'img/file.png'" style="width:100%;height:100%;" />
                                                </div>
                                                <div style="height:20px;width:70px;margin: 0 5px;line-height: 20px;text-align: center;word-break:keep-all;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">{{item.name}}</div>
                                                <div style="background:#ff7575;color:#FFF;text-align:center;width:20px;height:20px;line-height:20px;border-radius:10px; cursor:pointer;user-select: none;position:absolute;right:0;top:0;" @click="removeFile(index)">
                                                    <i class="el-icon-close"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </el-dialog>
                                </div>

                                <div class="input_box_btn_list">
                                    <div class="input_box_btn op sc hand" >
                                        <el-dropdown  trigger="click"  @command="handleCommand" style="height: 300px;margin: 20px 30px;width: 375px;display:flex" placement="bottom-start">
                                            <div class="input_box_btn_icon"><span style="color:#5a6fe6">#</span><span style="font-size: 14px;margin-top: 20px">添加话题</span></div>
                                            <el-dropdown-menu slot="dropdown" style="margin-top: 300px;">
                                                <div style="overflow: hidden; width: 270px; ">
                                                    <div style="overflow-y: scroll;width: 280px;height:400px;">
                                                        <el-input v-model="topic_like" style="width:90%; padding: 0 5%;" placeholder="请选择或搜索所属话题" @input="search_topic"></el-input>
                                                        <el-dropdown-item  :command="item" v-for="item in top_topic_list " :key= "item.id" >
                                                            <div style="overflow: hidden;">
                                                                <div style="float: left;">#{{item.name}}#</div>
                                                            </div>
                                                        </el-dropdown-item>
                                                    </div>
                                                </div>
                                            </el-dropdown-menu>
                                            <div style="overflow: hidden;line-height: 20px;padding-left: 20px;">
                                                <div style="float: left;" v-html="'#'+article.topicName+'#'"></div>
                                            </div>
                                        </el-dropdown>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 底部保存和转为草稿箱 -->
            <div style="width: 100%;height: 55px;position: fixed;bottom: 0;">
                <div style="margin: 0 auto;background: #F2F2F5;;height: 55px;border-top: 1px solid silver;border-bottom: 1px solid silver;">
                    <div style="width: 60px;line-height: 35px;background: #031F88;margin-top: -5px;color: #FFFFFF;text-align: center;font-size: 14px;float: right;cursor: pointer;margin-right: 410px;"  @click="save_article()">保存</div>
                </div>
            </div>
        </div>
        <Bottom></Bottom>
    </div>
</template>

<script>
    import Editor from "@/components/common/Editor.vue"
    import Bottom from "../../components/common/Bottom";

    export default {
        components: {
            Bottom,
            Editor
        },
        computed: {
            user() {
                return this.$store.state.user
            }
        },
        data() {
            return {
                id:"",
                article:{
                    enclosure: []
                },
                thumbs:[
                    {
                        "imgs": [
                            {
                                "img": "https://oss.baigongbao.com/img/thumbnail1.jpg",
                                "checked": 0
                            },
                            {
                                "img": "https://oss.baigongbao.com/img/thumbnail2.jpg",
                                "checked": 0
                            },
                            {
                                "img": "https://oss.baigongbao.com/img/thumbnail3.jpg",
                                "checked": 0
                            },
                            {
                                "img": "https://oss.baigongbao.com/img/thumbnail4.png",
                                "checked": 0
                            },

                        ],
                        "is_selected": 0
                    }
                ],
                channel_list:[],
                form:"1",
                isreprinted:false,
                auth:"1",
                //话题
                top_topic_list:[],
                topic_like:"",
                //上传
                upload_file_open: false,
                file_tag_name: 'pc',
                fmList:[],
                rmFileArr:[]
            }
        },
        mounted: function () {
            this.id = this.$route.params.id;
            this.get_top_topic_list();  //话题列表
            this.get_article_info();    //获取文章详情
        },
        methods:{
            //获取文章详情
            get_article_info:function(){
                var that = this;
                this.newApi.getRoomContentInfo({
                    id:that.id
                }).then((res)=>{
                    that.article = res.data.content;
                    that.form = that.article.form + "";
                    if(that.article.isReprinted == 1){
                        that.isreprinted = true;
                    }
                    if(that.article.channelId == 0){
                        that.article.channelId = "";
                    }
                    that.article.enclosure = JSON.parse(that.article.enclosure)
                }).catch((err)=>{
                    console.log(err)
                })
            },

            //获取话题列表
            get_top_topic_list:function(){
                var that = this;
                that.newApi.getTopTopicList({}).then((ret)=>{
                    if(ret.isSuccess == 1){
                        that.top_topic_list = ret.data;
                    }
                }).catch((err) => {
                    console.log(err);
                })
            },

            handleCommand(obj) {
                this.article.topicName = obj.name;
                this.article.topicId = obj.id;
                console.log(obj);
            },

            //话题搜索及显示
            search_topic(){
                var that = this;
                if (that.topic_like !="") {
                    let param = {};
                    param.like = that.topic_like;
                    that.newApi.getTopTopicList(param).then((ret)=>{
                        if(ret.isSuccess == 1){
                            that.top_topic_list = ret.data;
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                }else{
                    that.get_top_topic_list();
                }
            },

            //上传附件
            upload_file:function(params){
                var that = this;
                var file = params.file;
                var temp = 'blob:' + that.utils.random_string(8);
                file.img_url = temp;
                that.fmList.push(file);
                var obj = {
                    img_url:temp,
                    name:file.name,
                    size:file.size
                };
                that.article.enclosure.push(obj);
                console.log(that.article.enclosure);
            },

            //移除附件
            removeFile:function(index){
                var that = this;
                if(that.article.enclosure[index].img_url.indexOf("blob") == -1){
                    that.rmFileArr.push(that.article.enclosure[index]);
                }else{
                    if(that.fmList.length > 0){
                        for (let i = 0; i < that.fmList.length; i++) {
                            if(that.article.enclosure[index].temp == that.fmList[i].temp){
                                that.fmList.splice(i,1);
                            }
                        }
                    }
                }
                that.article.enclosure.splice(index,1);
                console.log(that.article.enclosure);
                console.log(that.fmList);
                console.log(that.rmFileArr);
            },

            //移除封面
            removeImg:function(){
                var that = this;
                that.utils.confirm("移除这个封面？",function(){
                    that.article.cover = "";
                })
            },

            //生成文章封面
            create_thumb_pic(){
                var that=this;
                var imgReg = /<img.*?(?:>|\/>)/gi;
                var srcReg = /src=['"]?([^'"]*)['"]?/i;
                let chil = that.$refs.ch;
                var content = chil.get_content();
                content =  content.replace(/amp;/g,"");
                var arr = content.match(imgReg);

                if(arr == null){
                    that.thumbs[0].is_selected = 1;
                    console.log(that.thumbs);
                }else{
                    var imgs = [];
                    for (var i = 0; i < arr.length && i < 10; i++) {
                        var src = arr[i].match(srcReg);
                        imgs.push(src[1]);
                    }

                    //替换需要转换OSS的图片地址
                    let param = {};
                    param.imgs = JSON.stringify(imgs);
                    that.newApi.uploadThumbImg(param).then((ret)=>{
                        if(ret.isSuccess == 1){
                            var imgs = [];
                            for (var i = 0; i < ret.data.length; i++) {
                                var img = {};
                                img.img = ret.data[i];
                                img.checked = 0;
                                imgs.push(img);
                            }
                            that.thumbs[0].imgs = imgs;
                            that.thumbs[0].is_selected = 1;
                            that.utils.sus('图片上传完毕!');
                        }
                    })
                }
            },

            //监听选中封面样式
            change_border(img,imgIndex){
                var data = this.thumbs[0].imgs;
                for (var i = 0;i < data.length;i++){
                    this.thumbs[0].imgs[i].checked = 0;
                    if(i == imgIndex){
                        this.thumbs[0].imgs[i].checked = 1;
                    }
                }

                this.article.cover = this.getCaption(img);
            },
            getCaption:function(obj){
                var index=obj.lastIndexOf("=");
                obj=obj.substring(index+1,obj.length);
                obj=obj.slice(0);
                obj=obj.replace(/'/g,'');
                obj=obj.replace(/>/g,'');
                return obj;
            },

            save_article(){
                var that = this;
                //处理附件统一上传
                if(that.fmList.length > 0 && that.article.enclosure.length > 0){
                    for (let i = 0; i < that.article.enclosure.length; i++) {
                        if(that.article.enclosure[i].img_url.indexOf("blob") > -1){
                            that.article.enclosure.splice(i);
                        }
                    }
                    let filesPromise = [];
                    for (let i = 0; i < that.fmList.length; i++) {
                        filesPromise.push(new Promise(function (resolve) {
                            that.utils.upload(that.fmList[i],function(url){
							    if (!url){
							       return false;
							    }
                                let obj = {};
                                obj.img_url = that.ossUrl + 'img/file.png';
                                obj.name = that.fmList[i].name.substring(0, that.fmList[i].name.lastIndexOf("."));
                                obj.size = that.fmList[i].size;
                                obj.type = that.utils.get_suffix(that.fmList[i].name);
                                obj.url = url;
                                that.article.enclosure.push(obj);
                                resolve(obj);
                            })
                        }))
                    }
                    // eslint-disable-next-line no-unused-vars
                    Promise.all(filesPromise).then(res=>{
                        console.log(that.article.enclosure);
                        that.save_article_submit();
                    })
                }else{
                    that.save_article_submit();
                }
            },

            //保存发布
            save_article_submit(){
                var that = this;
                let chil = that.$refs.ch;
                let content = chil.get_content();
                that.article.content = content.replace(/amp;/g,"");
                that.article.form = that.form;

                if(that.isreprinted == true){
                    that.article.isreprinted = 1;
                }else{
                    that.article.isreprinted = 2;
                }

                var params = {};
                params.operationType = 2;
                params.form = that.article.form;
                params.title = that.article.title;
                params.content = that.article.content;
                params.enclosure = JSON.stringify(that.article.enclosure);
                params.cover = that.article.cover;
                params.isComment = that.article.isComment;
                params.isReprinted = that.article.isReprinted;
                params.ReprintedAddress = that.article.ReprintedAddress;
                params.topicId = that.article.topicId;
                params.channelId = that.article.channelId;

                if(params.title == ""){
                    that.utils.err("请填写标题");
                    return false;
                }
                if(params.content == ""){
                    that.utils.err("请填写内容");
                    return false;
                }
                if(params.form == "2"){
                    if(params.ReprintedAddress == ""){
                        that.utils.err("请填写转载地址");
                        return false;
                    }
                }
                that.articleAddOrSave(params);
            },
            articleAddOrSave(params){
                var that = this;
                that.newApi.editTemplateContent({content: JSON.stringify(params), id: that.id}).then((ret)=>{
                    if(ret.isSuccess == 1){
                        that.utils.sus("修改成功");
                        that.$router.go(-1);
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            }
        }
    }
</script>

<style scoped>
    /deep/ .el-tabs__header{
        margin: 0;
    }
    .avatar {
        width: 280px;
        height: 180px;
        display: block;
        float: left;
        margin-top: 10px;
        border-radius: 0;
        margin-left: 25px;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        cursor: pointer;
        /*position: relative;*/
        overflow: hidden;
        float: left;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .cover_border{
        width: 150px;height: 100px;float:left; margin: 3px;
    }
    .cover_border.active{
        border: 1px solid #c1c1c1;height: 98px;width: 148px; box-shadow:1px 1px 3px 1px #409eff;
    }
    .el-radio{
        margin-right: 5px;
        font-size: 14px;
    }
    /deep/ .el-upload-dragger {
        width: 310px !important;
        margin-top: 5px;
    }

</style>
